<script setup lang="ts">
  import { MpText } from '@borg/ui';
  import { isUndefined } from '@borg/utils';

  const props = defineProps<{
    big?: boolean;
    noSeenIndicator?: boolean;
  }>();

  const jobCard = useJobCard.inject();
  const userInfo = useUserInfo();

  const isSeen = computed(() => {
    if (props.noSeenIndicator || !jobCard.job) {
      return false;
    }
    const userInfoValue = userInfo.getSeen(jobCard.job.id);
    return !isUndefined(userInfoValue) ? userInfoValue : jobCard.job.userInfo.isSeen;
  });
</script>

<template>
  <div
    v-if="jobCard.job"
    class="header"
  >
    <LinksJob
      :job="jobCard.job"
      :source="jobCard.source"
    >
      <MpText
        :type="big ? 'h4' : 'h5'"
        data-test="job-card-content-title"
        as="h3"
        link-card
        :link-visited="isSeen"
      >
        {{ jobCard.job.title }}
      </MpText>
    </LinksJob>
    <SaveJobButton
      class="header__save-button"
      :job="jobCard.job"
    />
  </div>
</template>

<style scoped lang="scss">
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__save-button {
      margin: var(--mp-space-20) 0 0 var(--mp-space-60);
      align-self: start;
    }
  }
</style>
