import { JobDetailsSource, JobGroup, JobPreview, JobSimple } from '@borg/types';

type CardInput = JobGroup | JobSimple;

export const useJobCard = createInjector('use-job-card', () => {
  const job = ref<JobPreview>();
  const organization = ref<JobGroup['organization']>();
  const source = ref<JobDetailsSource>();

  function set(item: CardInput, _source?: JobDetailsSource) {
    if ('jobs' in item) {
      job.value = item.jobs[0];
      organization.value = item.organization;
    } else {
      job.value = item;
      organization.value = item.organization;
    }
    source.value = _source;
  }

  return reactive({
    job,
    organization,
    source,
    set,
  });
});
