<script setup lang="ts">
  import { MpText } from '@borg/ui';
  import { JobDetails } from '@borg/types';

  const jobCard = useJobCard.inject();

  const tags = computed(() => {
    let _tags: JobDetails['tags'] = [];
    if (jobCard.job?.salary) {
      const salaryText = getSalaryString(
        jobCard.job.salary.from,
        jobCard.job.salary.to,
        jobCard.job.salary.currency,
      );
      if (salaryText) {
        _tags.push({ key: 'salary', name: salaryText });
      }
    }
    if (jobCard.job?.tags?.length) {
      const restOfTags = jobCard.job.tags.map((tag) => {
        return {
          key: 'custom',
          name: tag,
        };
      });
      _tags = [..._tags, ...restOfTags];
    }
    return _tags;
  });
</script>

<template>
  <div
    v-if="tags.length || jobCard.job?.userInfo.isNew"
    class="tags"
  >
    <MpText
      v-if="jobCard.job?.userInfo.isNew"
      type="body2"
      no-margin
      semibold
      class="tags__new-job"
    >
      {{ $t('JOB_CARD.NEW_JOB') }}
    </MpText>
    <JobTags
      :tags="tags"
      no-margin
    />
  </div>
</template>

<style scoped lang="scss">
  .tags {
    display: flex;
    gap: var(--mp-space-30);
    flex-wrap: wrap;

    &__new-job {
      --new-job-color: var(--mp-color-secondary-dark);

      width: fit-content;
      color: var(--new-job-color);
      padding: var(--mp-space-10) var(--mp-space-30);
      border: 1px solid var(--new-job-color);
      border-radius: var(--mp-space-20);

      @include dark-mode {
        --new-job-color: var(--mp-color-secondary-light);
      }
    }
  }
</style>
