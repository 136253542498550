<script setup lang="ts">
  import { MpText } from '@borg/ui';

  const jobCard = useJobCard.inject();
</script>

<template>
  <MpText
    v-if="jobCard.organization"
    class="subtitle"
    no-margin
    semibold
  >
    {{
      jobCard.organization === 'incognito'
        ? $t('ORGANIZATION_INCOGNITO')
        : jobCard.organization.name
    }}
  </MpText>
</template>

<style scoped lang="scss">
  .subtitle {
    color: var(--mp-color-text-20);
  }
</style>
